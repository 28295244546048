import './Header.css';

function Header(props) {


  return (
    <div id="Header">
      <div id="Langs">
        <div
          className={"lang " + (props.lang === "uk" ? "active" : "")}
          onClick={() => props.handleLangSwitch("uk")}>
          UA
        </div>
        <div
          className={"lang " + (props.lang === "de" ? "active" : "")}
          onClick={() => props.handleLangSwitch("de")}>
          DE
        </div>
        <div
          className={"lang " + (props.lang === "en" ? "active" : "")}
          onClick={() => props.handleLangSwitch("en")}>
          EN
        </div>
      </div>
    </div>
  );
}

export default Header;
