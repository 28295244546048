import './Common.css';

const donateButtonUa = "Зробити внесок";
const donateButtonEn = "Donate";
const donateButtonDe = "Donate";


export function DonateButton (props) {
  let donate;
  switch (props.lang) {
    case "uk":
      donate = donateButtonUa;
      break;
    case "de":
      donate = donateButtonDe;
      break;
    default:
      donate = donateButtonEn;
  }
  return (
    <div className="donate-button" onClick={()=>props.handlePageSwitch("donate")}>
      <span>{donate}</span>
      <span className="donate-button-plus">+</span>
    </div>
  );
}

export function Uplus (props) {
  return (
    <div className={"u-plus" + (props.hollow ? " hollow" : "")}>U&#x200A;+</div>
  );
}


export function Pluses (props) {
  return (
    <div className="pluses">
      <div className="pluses-row">+&nbsp;&nbsp;+&nbsp;&nbsp;+&nbsp;&nbsp;+</div>
      <div className="pluses-row">+&nbsp;&nbsp;+&nbsp;&nbsp;+&nbsp;&nbsp;+</div>
      <div className="pluses-row">+&nbsp;&nbsp;+&nbsp;&nbsp;+&nbsp;&nbsp;+</div>
      <div className="pluses-row">+&nbsp;&nbsp;+&nbsp;&nbsp;+&nbsp;&nbsp;+</div>
    </div>
  );
}

export function PlusesShifted (props) {
  return (
    <div className="pluses-shifted">
      <div className="pluses-shifted-row">+&nbsp;&nbsp;+&nbsp;&nbsp;+&nbsp;&nbsp;</div>
      <div className="pluses-shifted-row">&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;+&nbsp;&nbsp;+</div>
      <div className="pluses-shifted-row">+&nbsp;&nbsp;+&nbsp;&nbsp;+&nbsp;&nbsp;</div>
      <div className="pluses-shifted-row">&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;+&nbsp;&nbsp;+</div>
      <div className="pluses-shifted-row">+&nbsp;&nbsp;+&nbsp;&nbsp;+&nbsp;&nbsp;</div>
      <div className="pluses-shifted-row">&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;+&nbsp;&nbsp;+</div>
    </div>
  );
}

export function Table (props) {
  return (
    <div className="table">
      <div className="table-row">
        <div className="table-cell">{props.rows[0][0]}</div>
        <div className="table-cell">{props.rows[0][1]}</div>
        <div className="table-cell">{props.rows[0][2]}</div>
      </div>
      <div className="table-row">
        <div className="table-cell">{props.rows[1][0]}</div>
        <div className="table-cell">{props.rows[1][1]}</div>
        <div className="table-cell">{props.rows[1][2]}</div>
      </div>
      <div className="table-row">
        <div className="table-cell">{props.rows[2][0]}</div>
        <div className="table-cell">{props.rows[2][1]}</div>
        <div className="table-cell">{props.rows[2][2]}</div>
      </div>
      <div className="table-row">
        <div className="table-cell">{props.rows[3][0]}</div>
        <div className="table-cell">{props.rows[3][1]}</div>
        <div className="table-cell">{props.rows[3][2]}</div>
      </div>
    </div>
  );
}
