import React, { Component } from 'react';
import './Reports.css';
import {DonateButton, Table} from './Common.js';


class Reports extends Component {

  constructor(props) {
    super(props);
    this.state = {
      enlargedImg: null
    };
    this.toggleEnlargeImage = this.toggleEnlargeImage.bind(this);
    this.imgClassName = this.imgClassName.bind(this);
  }

  toggleEnlargeImage(imgRef) {
    if (this.state.enlargedImg !== null) {
      this.setState({enlargedImg: null});
    } else {
      this.setState({enlargedImg: imgRef});
    }
  }

  imgClassName (cellRef) {
    return "reports-img" +
      (this.state.enlargedImg === cellRef ? " enlarged" : "");
  }

  render () {
    const row1 = [
      <img className={this.imgClassName("1-1")}
           src="images/report-1-1.jpg"
           alt="report 1 1"
           onClick={() => this.toggleEnlargeImage("1-1")} />,
      <img className={this.imgClassName("1-2")}
           src="images/report-1-2.jpg"
           alt="report 1 2"
           onClick={() => this.toggleEnlargeImage("1-2")} />,
      <img className={this.imgClassName("1-3")}
           src="images/report-1-3.png"
           alt="report 1 3"
           onClick={() => this.toggleEnlargeImage("1-3")} />
    ];

    const row2 = [
      <img className={this.imgClassName("2-1")}
           src="images/report-2-1.png"
           alt="report 2 1"
           onClick={() => this.toggleEnlargeImage("2-1")} />,
      <img className={this.imgClassName("2-2")}
           src="images/report-2-2.png"
           alt="report 2 2"
           onClick={() => this.toggleEnlargeImage("2-2")} />,
      <img className={this.imgClassName("2-3")}
           src="images/report-2-3.png"
           alt="report 2 3"
           onClick={() => this.toggleEnlargeImage("2-3")} />
    ];

    const row3 = [
      <img className={this.imgClassName("3-1")}
           src="images/report-3-1.png"
           alt="report 3 1"
           onClick={() => this.toggleEnlargeImage("3-1")} />,
      <img className={this.imgClassName("3-2")}
           src="images/report-3-2.jpg"
           alt="report 3 2"
           onClick={() => this.toggleEnlargeImage("3-2")} />,
      <img className={this.imgClassName("3-3")}
           src="images/report-3-3.jpg"
           alt="report 3 3"
           onClick={() => this.toggleEnlargeImage("3-3")} />
    ];

    const row4 = [
      <img className={this.imgClassName("4-1")}
           src="images/report-4-1.jpg"
           alt="report 4 1"
           onClick={() => this.toggleEnlargeImage("4-1")} />,
      <img className={this.imgClassName("4-2")}
           src="images/report-4-2.jpg"
           alt="report 4 2"
           onClick={() => this.toggleEnlargeImage("4-2")} />,
      <img className={this.imgClassName("4-3")}
           src="images/report-4-3.jpg"
           alt="report 4 3"
           onClick={() => this.toggleEnlargeImage("4-3")} />
    ];

    return (
      <div id="Reports">
        <div className="reports-table">
          <Table rows={[row1, row2, row3, row4]} />
        </div>
        <div className="reports-donate-button">
          <DonateButton lang={this.props.lang} handlePageSwitch={this.props.handlePageSwitch} />
        </div>
      </div>
    );
  }
}

export default Reports;
