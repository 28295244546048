import React, { Component } from 'react';
import './Menu.css';


const homeUa = "Про проект";
const homeEn = "About";
const homeDe = "Über";
const neededUa = "Актуальні потреби";
const neededEn = "Actual Needs";
const neededDe = "Tatsächliche Bedürfnisse";
const donateUa = "Зробити внесок";
const donateEn = "Donate";
const donateDe = "Donate";
const reportsUa = "Звітність";
const reportsEn = "Reports";
const reportsDe = "Berichte";
const teamUa = "Команда";
const teamEn = "Team"
const teamDe = "Team"
const contactsUa = "Контакти";
const contactsEn = "Contacts";
const contactsDe = "Kontakte";

class Menu extends Component {

  WINDOW_CHANGE_EVENT;

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showMenuElements: false
    };
    this.WINDOW_CHANGE_EVENT = ('onorientationchange' in window) ? 'orientationchange':'resize';
    this.toggleShowMenuElements = this.toggleShowMenuElements.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  toggleShowMenuElements() {
    this.setState({showMenuElements: !this.state.showMenuElements});
  }

  toggleMenu() {
    if (this.state.isOpen) {
      this.setState({isOpen:false});
      setTimeout(this.toggleShowMenuElements, 400);
    } else {
      this.setState({isOpen: true, showMenuElements: true});
    }
  };

  closeMenu() {
      if (this.state.isOpen) {
          this.toggleMenu();
      }
  }

  handleMenuClick(newPage) {
    this.props.handlePageSwitch(newPage);
    this.closeMenu();
  }


  componentDidMount() {
    window.addEventListener(this.WINDOW_CHANGE_EVENT, this.closeMenu);
  }

  render () {

    let home;
    let needed;
    let donate;
    let reports;
    let team;
    let contacts;

    switch (this.props.lang) {
      case "uk":
        home = homeUa;
        needed = neededUa;
        donate = donateUa;
        reports = reportsUa;
        team = teamUa;
        contacts = contactsUa;
        break;
      case "de":
        home = homeDe;
        needed = neededDe;
        donate = donateDe;
        reports = reportsDe;
        team = teamDe;
        contacts = contactsDe;
        break;
      default:
        home = homeEn;
        needed = neededEn;
        donate = donateEn;
        reports = reportsEn;
        team = teamEn;
        contacts = contactsEn;
    }

    const currentPageClass = (p) => p === this.props.page ? " current" : "";

    return (
      <div className={"menu-wrapper" + (this.state.isOpen ? " open" : "")} id="Menu">
        <div className={"menu-toggle" + (this.state.isOpen ? " x" : "")} id="toggle" onClick={(e) => {this.toggleMenu(); e.preventDefault();}}>
          <s className="bar"></s><s className="bar"></s>
        </div>
        <div className={"menu-elements" + (this.state.showMenuElements ? " open" : "")}>
          <div className={"menu-item" + currentPageClass("home")} onClick={() => this.handleMenuClick("home")}>{home}</div>
          <div className={"menu-item" + currentPageClass("needed")} onClick={() => this.handleMenuClick("needed")}>{needed}</div>
          <div className={"menu-item" + currentPageClass("donate")} onClick={() => this.handleMenuClick("donate")}>{donate}</div>
          <div className={"menu-item" + currentPageClass("reports")} onClick={() => this.handleMenuClick("reports")}>{reports}</div>
          <div className={"menu-item" + currentPageClass("team")} onClick={() => this.handleMenuClick("team")}>{team}</div>
          <div className={"menu-item" + currentPageClass("contacts")} onClick={() => this.handleMenuClick("contacts")}>{contacts}</div>
        </div>
      </div>
    );
  }
}

export default Menu;
