import './Needed.css';
import {DonateButton} from './Common.js';


const headersUa = [
  "Ціна, $",
  "Кількість, шт.",
  "Вартість з доставкою, $",
  "Необхідно",
  "Маємо",
  "Не вистачає"
];
const headersEn = [
  "Price, $",
  "Amount",
  "Cost with delivery, $",
  "We need",
  "We have",
  "Is missing"

];


const itemsUa = [
  [
    "Specna Arms AK-74 SA-J03 Edge Black assault rifle",
    "Donetsk region",
    "267",
    "50",
    "13350",
    "images/item14.jpg"
  ],
  [
    "D-Boys K-55A Grenade Launcher Black",
    "Donetsk region",
    "137",
    "7",
    "959",
    "images/item15.png"
  ],
  [
    "Pyrosoft G25D sleeve",
    "Donetsk region",
    "54",
    "7",
    "378",
    "images/item16.png"
  ],
  [
    "Grenade airsoft Pyrosoft RGD-5 Pyro-5 filler peas",
    "Donetsk region",
    "1.9",
    "70",
    "133",
    "images/item17.png"
  ],
  [
    "Pyrosoft airsoft grenade",
    "Donetsk region",
    "1",
    "1",
    "1",
    "images/item18.png"
  ],
  [
    "SPECNA ARMS BY BLS КУЛІ EDGE PRECISION 0.23G 1KG 15468",
    "Donetsk region",
    "10.15",
    "50",
    "507.5",
    "images/item19.png"
  ],
  [
    "Pyramex Itek Anti-Fog Clear ballistic glasses",
    "Donetsk region",
    "9.5",
    "50",
    "475",
    "images/item20.png"
  ],
  [
    "Electro River Flux universal charger",
    "Donetsk region",
    "29",
    "3",
    "87",
    "images/item21.png"
  ],
  [
    "CYMA AK74 125 mm",
    "Donetsk region",
    "13.5",
    "30",
    "405",
    "images/item22.png"
  ]
];

const itemsEn = [
  [
    "Specna Arms AK-74 SA-J03 Edge Black assault rifle",
    "Donetsk region",
    "267",
    "50",
    "13350",
    "images/item14.jpg"
  ],
  [
    "D-Boys K-55A Grenade Launcher Black",
    "Donetsk region",
    "137",
    "7",
    "959",
    "images/item15.png"
  ],
  [
    "Pyrosoft G25D sleeve",
    "Donetsk region",
    "54",
    "7",
    "378",
    "images/item16.png"
  ],
  [
    "Grenade airsoft Pyrosoft RGD-5 Pyro-5 filler peas",
    "Donetsk region",
    "1.9",
    "70",
    "133",
    "images/item17.png"
  ],
  [
    "Pyrosoft airsoft grenade",
    "Donetsk region",
    "1",
    "1",
    "1",
    "images/item18.png"
  ],
  [
    "SPECNA ARMS BY BLS КУЛІ EDGE PRECISION 0.23G 1KG 15468",
    "Donetsk region",
    "10.15",
    "50",
    "507.5",
    "images/item19.png"
  ],
  [
    "Pyramex Itek Anti-Fog Clear ballistic glasses",
    "Donetsk region",
    "9.5",
    "50",
    "475",
    "images/item20.png"
  ],
  [
    "Electro River Flux universal charger",
    "Donetsk region",
    "29",
    "3",
    "87",
    "images/item21.png"
  ],
  [
    "CYMA AK74 125 mm",
    "Donetsk region",
    "13.5",
    "30",
    "405",
    "images/item22.png"
  ]
];

const summary = [
  "16095.5 $",
  "3000 $",
  "13095.5 $"
]


function Needed(props) {
  const headers = props.lang === "uk" ? headersUa : headersEn;
  const items = props.lang === "uk" ? itemsUa : itemsEn;

  const tableItem = (item, idx) =>
    <div className="table-item" key={idx}>
      <div className="table-item-cell"><img src={item[5]} alt="item" className="table-item-img"></img></div>
      <div className="table-item-info">
        <div className="table-item-info-name table-item-cell"><div>{item[0]}</div></div>
        <div className="table-item-info-team table-item-cell">{item[1]}</div>
        <div className="table-item-info-cost">
          <div className="table-item-info-cost-info table-item-cell">
            <div>{headers[0]}</div>
            <div>{item[2]}</div>
          </div>
          <div className="table-item-info-cost-info table-item-cell">
            <div>{headers[1]}</div>
            <div>{item[3]}</div>
          </div>
          <div className="table-item-info-cost-info table-item-cell">
            <div>{headers[2]}</div>
            <div>{item[4]}</div>
          </div>
        </div>
      </div>
    </div>

  const tableFooter =
    <div className="table-footer">
      <div className="table-footer-needed table-footer-cell">
        <div className="table-footer-title">{headers[3]}</div>
        <div className="table-footer-amount">{summary[0]}</div>
      </div>
      <div className="table-footer-available table-footer-cell">
        <div className="table-footer-title">{headers[4]}</div>
        <div className="table-footer-amount">{summary[1]}</div>
      </div>
      <div className="table-footer-missing table-footer-cell">
        <div className="table-footer-title">{headers[5]}</div>
        <div className="table-footer-amount">{summary[2]}</div>
      </div>
    </div>

  return (
    <div id="Needed">
      <div id="table-wrapper">
        {items.map(tableItem)}
        {tableFooter}
      </div>
      <div className="needed-donate-button">
        <DonateButton lang={props.lang} handlePageSwitch={props.handlePageSwitch} />
      </div>
    </div>
  );
}

export default Needed;
