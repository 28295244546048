import React, { Component } from 'react';
import './App.css';
import browserLang from 'browser-lang';

import Menu          from './Menu';
import Header        from './Header';
import Intro         from './Intro';
import Needed        from './Needed';
import Donate        from './Donate';
import Reports       from './Reports';
import Team          from './Team';
import Contacts      from './Contacts';


const initialLang = browserLang({
  languages: ['uk', 'en', 'de'],
  fallback: 'en',
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: initialLang,
      page: "home"
    };
    this.handleLangSwitch = this.handleLangSwitch.bind(this)
    this.handlePageSwitch = this.handlePageSwitch.bind(this)
  }

  handleLangSwitch(newLang) {
    this.setState({lang: newLang});
  }

  handlePageSwitch(newPage) {
    this.setState({page: newPage});
  }

  render () {
    let subPage;
    switch (this.state.page) {
      case "needed":
        subPage = <Needed lang={this.state.lang} handlePageSwitch={this.handlePageSwitch} />;
        break;
      case "donate":
        subPage = <Donate lang={this.state.lang} />;
        break;
      case "reports":
        subPage = <Reports lang={this.state.lang} handlePageSwitch={this.handlePageSwitch} />;
        break;
      case "team":
        subPage = <Team lang={this.state.lang} handlePageSwitch={this.handlePageSwitch} />;
        break;
      case "contacts":
        subPage = <Contacts lang={this.state.lang} handlePageSwitch={this.handlePageSwitch} />;
        break;
      case "home":
        subPage = <Intro lang={this.state.lang} handlePageSwitch={this.handlePageSwitch} />;
        break;
      default:
    }

    return (
      <div className="App">
        <Menu lang={this.state.lang} page={this.state.page} handlePageSwitch={this.handlePageSwitch} />
        <Header lang={this.state.lang} handleLangSwitch={this.handleLangSwitch} />
        {subPage}
      </div>
    );
  }
}


export default App;
