import './Contacts.css';
import {DonateButton, Pluses} from './Common.js';


const infoUa = [
  "Друже, ми залюбки відповімо на твої питання.",
  "Координатор проекту в Україні:  Олена Довгополова",
  "Координатор проекту в США: Валентина Москаленко",
  "Або доєднуйся до нашого Телеграм-каналу",
  "(новини, звітність, відповіді на питання)",
  "Шукаємо волонтерів у нашу команду."
]

const infoEn = [
  "Friend, we will gladly answer your questions.",
  "Project coordinator in Ukraine: Olena Dovgopolova",
  "Project coordinator in the USA: Valentina Moskalenko",
  "Or join our Telegram channel",
  "(news, reports, answers to questions)",
  "We are looking for volunteers to join our team."
]


function Contacts(props) {

  let info;
  switch (props.lang) {
    case "uk":
      info = infoUa;
      break;
    case "de":
      info = infoEn;
      break;
    default:
      info = infoEn;
  }

  return (
    <div id="Contacts">
      <div id="contact-elements">
        <div className="contacts-info">
          <div className="contacts-info-text">
            <div className="contacts-info-part">{info[0]}</div>
            <div className="contacts-info-part">
              <div>{info[1]}</div>
              <div><a href="mailto: edovgopolova@gmail.com">edovgopolova@gmail.com</a></div>
            </div>
            <div className="contacts-info-part">
              <div>{info[2]}</div>
              <div><a href="mailto: moskalenko.valentina@gmail.com">moskalenko.valentina@gmail.com</a></div>
            </div>
            <div className="contacts-info-part">
              <div>{info[3]}</div>
              <div>
                <a className="contacts-social-link" href="https://t.me/ukraine_plus_you" target="_blank" rel="noreferrer">
                  <img className="contacts-social-logo"
                       src="images/telegram_logo.svg"
                       alt="telegram logo" />
                  <span>https://t.me/ukraine_plus_you</span>
                </a>
              </div>
              <div>{info[4]}</div>
            </div>
            <div className="contacts-info-part">{info[5]}</div>
          </div>
          <Pluses className="contacts-pluses" />
        </div>
        <div className="contacts-donate-button">
          <DonateButton lang={props.lang} handlePageSwitch={props.handlePageSwitch} />
        </div>
      </div>
    </div>
  );
}

export default Contacts;
