import './Intro.css';
import {DonateButton, Pluses, Uplus} from './Common.js';

const intro1Ua = [
  "Привіт, дорогий друже. Проект “Україна +”, де “+” - і є саме ти, " +
  "було створено звичайними українськими дівчатами та хлопцями з метою підтримки " +
  "наших друзів / колег / родичів військових.",

  "Зараз багато благодійних організацій, які підтримують українську армію та " +
  "Сили територіальної оборони, працюють на високому рівні, допомагаючи одразу " +
  "великій кількості людей. Це іноді робить їх менш обізнаними щодо конкретних " +
  "потреб окремих військових (наприклад, правильний розмір, марка чи модель). " +
  "З цієї причини ми збираємо  допомогу для підтримки українських військових, " +
  "яких знаємо особисто і які можуть повідомити безпосередньо нам про свої поточні потреби.",

  "Ти можеш допомогти одним із 3-х способів:"
]

const intro2Ua = [

  "Долучитись до збору коштів для проектів у розділі ",
  "Купити необхідну річ самостійно і надіслати нам;",
  "Підтримати воїна добрим листом, надіславши його нам. " +
  "Не варто недооцінювати слова підтримки, іноді правильні слова можуть врятувати життя.",
]

const intro3Ua = [
  "Ми вдячні за твій вклад у розвиток демократії у світі!",
  "Україна і світ переможуть!"
  ]

const actualNeedsUa = "Актуальні потреби";


const intro1En = [
  "Dear friends, the project “Ukraine+”, where “+” refers to you, " +
  "was created by regular Ukrainian women and men with a goal to support " +
  "our friends / colleagues / relatives-warriors.",

  "Currently, many charitable organizations that support the Ukrainian army and " +
  "Territorial defense forces, operate on a high level by helping a large number " +
  "of people at once. This makes them at times less aware of the particular needs " +
  "(for example the right size, brand or a model) of individual warriors. " +
  "For this reason, we are collecting funding and materials to support " +
  "the warriors that we personally know and which can communicate their exact " +
  "needs directly to us.",

  "You can also contribute in the following ways:"
]

const intro2En = [
  "Donate money, see the section ",
  "Buy the necessary item yourself and send it to us;",
  "Support the soldier with a kind message by sending it to us first. " +
  "Do not underestimate the power of words of support, sometimes the right words can save a life."
]

const intro3En = [
  "We are grateful for your contribution to the development of democracy in the world!",
  "Ukraine and the world will win!"
]

const actualNeedsEn = "Actual Needs"


const intro1De = [
  "Herzlich Willkommen bei UKRAINE+. " +
  "Im Projekt „UKRAINE+“ organisieren wir Unterstützung für die Männer und " +
  "Frauen der ukrainischen Territorialverteidigung. " +
  "Viele unserer Verwandten, Freunde und Bekannten sind im Einsatz in verschiedenen " +
  "Einheiten der Territorialverteidigung und in der offiziellen ukrainischen Armee.",

  "Trotz der vielen Unterstützung durch westliche Regierungen und Organisationen " +
  "fehlt in der Ukraine noch sehr viel Ausrüstung. Nicht alle ukrainischen " +
  "Kämpfer können mit der notwendigen Sicherheitsausrüstung ausgestattet werden " +
  "und sie riskieren dennoch ihr Leben im Kampf für die Freiheit. " +
  "Hier benötigen wir IHRE Unterstützung!",

  "Sie können auf folgende Weise beitragen:"
]

const intro2De = [
  "Siehe Abschnitt ",
  "Kaufen Sie den erforderlichen Artikel selbst und senden Sie ihn an uns;",
  "Unterstützen Sie Soldaten mit einer freundlichen Nachricht, indem Sie " +
  "diese zuerst an uns senden. Unterschätzen Sie nicht die Kraft von " +
  "unterstützenden Worten, manchmal können die richtigen Worte ein Leben retten."
]

const intro3De = [
  "Wir sind dankbar für Ihren Beitrag zur Entwicklung der Demokratie in der Welt!",
  "Die Ukraine und die Welt werden gewinnen!"
]

const actualNeedsDe = "Tatsächliche Bedürfnisse"


function Intro (props) {
  let intro1;
  let intro2;
  let intro3;
  let actualNeeds;

  switch (props.lang) {
    case "uk":
      intro1 = intro1Ua;
      intro2 = intro2Ua;
      intro3 = intro3Ua;
      actualNeeds = actualNeedsUa;
      break;
    case "de":
      intro1 = intro1De;
      intro2 = intro2De;
      intro3 = intro3De;
      actualNeeds = actualNeedsDe;
      break;
    default:
      intro1 = intro1En;
      intro2 = intro2En;
      intro3 = intro3En;
      actualNeeds = actualNeedsEn;
  }



  const introElements = (elms) => elms.map((elem, idx) =>
      <div className ="intro-text-elem" key={idx}>{elem}</div>);

  return (
    <div id="Intro">
      <div className="intro-elements">
        <div id="Logo"><Uplus hollow={true} /></div>
        <div className="intro-text-and-pluses">
          <div className="intro-text">
            <div>{introElements(intro1)}</div>
            <div className="intro-enum-elem">
              <span className="intro-enum">1)</span>
              {intro2[0]}
              <span className="intro-actual-needs-button" onClick={()=>props.handlePageSwitch("needed")}>
                <span>{actualNeeds}</span>
              </span>
              <span>;</span>
            </div>
            <div className="intro-enum-elem">
              <span className="intro-enum">2)</span>{intro2[1]}
            </div>
            <div className="intro-enum-elem">
              <span className="intro-enum">3)</span>{intro2[2]}
            </div>
            <div>{introElements(intro3)}</div>
          </div>
          <div className="intro-pluses"><Pluses /><Pluses /></div>
        </div>
        <div className="intro-donate-button">
          <DonateButton lang={props.lang} handlePageSwitch={props.handlePageSwitch} />
        </div>
        <div className="intro-background">
          <img className="intro-background-img" src="images/spots.png" alt="background" />
          <div className="intro-ukraine-plus">
            <div className="intro-ukraine-plus-ukraine">Ukraine</div>
            <div className="intro-ukraine-plus-plus">+</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
