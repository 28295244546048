import React, { Component } from 'react';

import './Donate.css';

const fromUkraineUa = [
  "З Україні - Олена",
  "PayPal",
  "edovgopolova@gmail.com",
  "Довгополова О.С., Монобанк",
  "4441111152524066",
  "UA983220010000026204336578579"
]

const fromUkraineEn = [
  "From Ukraine - Olena",
  "PayPal",
  "edovgopolova@gmail.com",
  "Dovgopolova O.S., Monobank",
  "4441111152524066",
  "UA983220010000026204336578579"
]

const fromUsaUa = [
  "З США - Валентина",
  "PayPal",
  "moskalenko.valentina@gmail.com",
]

const fromUsaEn = [
  "From the US - Valentina",
  "PayPal",
  "moskalenko.valentina@gmail.com",
]

const fromEuropeUa = [
  "З Європи - Катерина",
  "PayPal",
  "kuksova.kateryna@gmail.com",
  "IBAN",
  "DE59 6655 0070 1000 6671 45"
]

const fromEuropeEn = [
  "From Europe - Kateryna",
  "PayPal",
  "kuksova.kateryna@gmail.com",
  "IBAN",
  "DE59 6655 0070 1000 6671 45"
]


const copyToClipboardUa = "Копіювати в буфер обміну"
const copyToClipboardEn = "Copy to clipboard"


class Donate extends Component {
  constructor(props) {
    super(props);
    this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this);
  }

  handleCopyToClipboard(text) {
    navigator.clipboard.writeText(text);
  }


  render () {
    const fromUkraine = this.props.lang === "uk" ? fromUkraineUa : fromUkraineEn;
    const fromUsa = this.props.lang === "uk" ? fromUsaUa : fromUsaEn
    const fromEurope = this.props.lang === "uk" ? fromEuropeUa : fromEuropeEn;

    const copyText = this.props.lang === "uk" ? copyToClipboardUa : copyToClipboardEn;

    const paypalButton =
      <form action="https://www.paypal.com/donate" method="post" target="_top">
        <input type="hidden" name="hosted_button_id" value="TW8BTDVMPSWLY" />
        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
        <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
      </form>

    return (
      <div id="Donate">
        <div className="donate-wrapper">
          <div className="bank-items">
            <div className="bank-item">
              <div className="bank-item-title">{fromUkraine[0]}</div>
              <div className="bank-item-element">{fromUkraine[1]}</div>
              <div className="bank-item-element">
                <span>{fromUkraine[2]}</span>
                <img className="bank-item-copy"
                     title={copyText}
                     onClick={() => this.handleCopyToClipboard(fromUkraine[2])}
                     src="images/clipboard.svg"
                     alt="copy to clipoard" />
              </div>
              <div className="bank-item-element">{fromUkraine[3]}</div>
              <div className="bank-item-element">
                <span>{fromUkraine[4]}</span>
                <img className="bank-item-copy"
                     title={copyText}
                     onClick={() => this.handleCopyToClipboard(fromUkraine[4])}
                     src="images/clipboard.svg"
                     alt="copy to clipoard" />
              </div>
              <div className="bank-item-element">
                <span>{fromUkraine[5]}</span>
                <img className="bank-item-copy"
                     title={copyText}
                     onClick={() => this.handleCopyToClipboard(fromUkraine[5])}
                     src="images/clipboard.svg"
                     alt="copy to clipoard" />
              </div>
            </div>
            <div className="bank-item">
              <div className="bank-item-title">{fromUsa[0]}</div>
              <div className="bank-item-element">{fromUsa[1]}</div>
              <div className="bank-item-element">
                <span>{fromUsa[2]}</span>
                <img className="bank-item-copy"
                     title={copyText}
                     onClick={() => this.handleCopyToClipboard(fromUsa[2])}
                     src="images/clipboard.svg"
                     alt="copy to clipoard" />
              </div>
              {paypalButton}
            </div>
            <div className="bank-item">
              <div className="bank-item-title">{fromEurope[0]}</div>
              <div className="bank-item-element">{fromEurope[1]}</div>
              <div className="bank-item-element">
                <span>{fromEurope[2]}</span>
                <img className="bank-item-copy"
                     title={copyText}
                     onClick={() => this.handleCopyToClipboard(fromEurope[2])}
                     src="images/clipboard.svg"
                     alt="copy to clipoard" />
              </div>
              <div className="bank-item-element">{fromEurope[3]}</div>
              <div className="bank-item-element">
                <span>{fromEurope[4]}</span>
                <img className="bank-item-copy"
                     title={copyText}
                     onClick={() => this.handleCopyToClipboard(fromEurope[4])}
                     src="images/clipboard.svg"
                     alt="copy to clipoard" />
              </div>
            </div>
          </div>
          <div className="donate-images">
            <img className="donate-image" src="images/img-scramble-letters-u.svg" alt="letters-u-plus"></img>
            <img className="donate-image" src="images/img-scramble-letters-u.svg" alt="letters-u-plus"></img>
            <img className="donate-image" src="images/img-scramble-letters-u.svg" alt="letters-u-plus"></img>
            <img className="donate-image" src="images/img-scramble-letters-u.svg" alt="letters-u-plus"></img>
          </div>
        </div>
      </div>
    );
  }
}

export default Donate;
