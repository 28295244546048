import React, { Component } from 'react';
import './Team.css';
import {DonateButton} from './Common.js';


const teamMember1Ua = [
  "Валентина Москаленко",
  "Координатор проекту в США",
  "moskalenko.valentina@gmail.com"
]

const teamMember1En = [
  "Valentina Moskalenko",
  "Project coordinator in the USA",
  "moskalenko.valentina@gmail.com"
]

const teamMember2Ua = [
  "Олена Довгополова",
  "Координатор проекту в Україні",
  "edovgopolova@gmail.com"
]

const teamMember2En = [
  "Olena Dovgopolova",
  "Project coordinator in Ukraine",
  "edovgopolova@gmail.com"
]

const teamMember3Ua = [
  "Катерина Куксова",
  "Координатор проекту в Німеччині",
  "kuksova.kateryna@gmail.com"
]

const teamMember3En = [
  "Kateryna Kuksova",
  "Project coordinator in Germany",
  "kuksova.kateryna@gmail.com"
]

const volunteersUa = "Шукаємо волонтерів"
const volunteersEn = "Looking for volunteers"


class Team extends Component {

  constructor(props) {
    super(props);
    this.state = {
      enlargedImg: null
    };
    this.toggleEnlargeImage = this.toggleEnlargeImage.bind(this);
    this.imgClassName = this.imgClassName.bind(this);
  }

  toggleEnlargeImage(imgRef) {
    if (this.state.enlargedImg !== null) {
      this.setState({enlargedImg: null});
    } else {
      this.setState({enlargedImg: imgRef});
    }
  }

  imgClassName (cellRef) {
    return "team-img" +
      (this.state.enlargedImg === cellRef ? " enlarged" : "");
  }

  render () {
    const teamMember1 = this.props.lang === "uk" ? teamMember1Ua : teamMember1En;
    const teamMember2 = this.props.lang === "uk" ? teamMember2Ua : teamMember2En;
    const teamMember3 = this.props.lang === "uk" ? teamMember3Ua : teamMember3En;
    const volunteers = this.props.lang === "uk" ? volunteersUa : volunteersEn;

    const row1 = [
      <img className={this.imgClassName("1-1")}
           src="images/team1.png"
           alt="team 1 1"
           onClick={() => this.toggleEnlargeImage("1-1")} />,
      <div className="team-member">
        <div className="team-member-row team-member-name">{teamMember1[0]}</div>
        <div className="team-member-row">{teamMember1[1]}</div>
        <div className="team-member-row">
          <a href={"mailto:" + teamMember1[2]}>{teamMember1[2]}</a>
        </div>
      </div>
    ];

    const row2 = [
      <img className={this.imgClassName("2-1")}
           src="images/team2.png"
           alt="team 2 1"
           onClick={() => this.toggleEnlargeImage("2-1")} />,
      <div className="team-member">
        <div className="team-member-row team-member-name">{teamMember2[0]}</div>
        <div className="team-member-row">{teamMember2[1]}</div>
        <div className="team-member-row">
          <a href={"mailto:" + teamMember2[2]}>{teamMember2[2]}</a>
        </div>
      </div>
    ];

    const row3 = [
      <img className={this.imgClassName("3-1")}
           src="images/team3.png"
           alt="team 3 1"
           onClick={() => this.toggleEnlargeImage("3-1")} />,
      <div className="team-member">
        <div className="team-member-row team-member-name">{teamMember3[0]}</div>
        <div className="team-member-row">{teamMember3[1]}</div>
        <div className="team-member-row">
          <a href={"mailto:" + teamMember3[2]}>{teamMember3[2]}</a>
        </div>
      </div>
    ];

    const row4 =[
      <div className="team-u-plus">U&#x200A;?</div>,
      <div className="team-volunteers">
        <div className="team-volunteers-text">
          <div className="team-volunteers-text-1">Ukraine&nbsp;+</div>
          <div className="team-volunteers-text-2">{volunteers}</div>
        </div>
        <div>
          <img className="team-img-decor" src="images/img-scrambled-letters-u.png" alt="decoration"/>
        </div>
      </div>
    ];

    return (
      <div id="Team">
        <div className="team-table">
          <div className="team-table-row">
            <div className="team-table-cell-short">{row1[0]}</div>
            <div className="team-table-cell-long">{row1[1]}</div>
          </div>
          <div className="team-table-row">
            <div className="team-table-cell-short">{row2[0]}</div>
            <div className="team-table-cell-long">{row2[1]}</div>
          </div>
          <div className="team-table-row">
            <div className="team-table-cell-short">{row3[0]}</div>
            <div className="team-table-cell-long">{row3[1]}</div>
          </div>
          <div className="team-table-row">
            <div className="team-table-cell-short">{row4[0]}</div>
            <div className="team-table-cell-long">{row4[1]}</div>
          </div>
        </div>
        <div className="team-donate-button">
          <DonateButton lang={this.props.lang} handlePageSwitch={this.props.handlePageSwitch} />
        </div>
      </div>
    );
  }
}

export default Team;
